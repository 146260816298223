import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tag,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";
import ContactFieldsList from "../Modals/ContactList";
import { useFormContext } from "react-hook-form";
import PromptTemplateSelect from "./PromptTemplateSelect";
import ConversationFlowSelect from "./ConversationFlowSelect";

const Prompt = () => {
  const [customFieldTags, setCustomFieldTags] = useState([]);
  const [promptType, setPromptType] = useState("");

  const promptTypeOptions = [
    { label: "Prompt", value: "prompt" },
    { label: "Conversation Flow", value: "conversation_flow" },
  ];

  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    isOpen: contactIsOpen,
    onOpen: contactOnOpen,
    onClose: contactOnClose,
  } = useDisclosure();

  const inputRef = useRef(null);

  const extractCustomFieldKeywords = (text) => {
    const regex = /\{{(.*?)\}}/g;
    let matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  };

  useEffect(() => {
    const keywords = extractCustomFieldKeywords(watch("systemPrompt"));
    setCustomFieldTags(keywords);
  }, [watch("systemPrompt")]);

  // useEffect(() => {
  //   if(watch("systemPrompt").length) {
  //     setPromptType({ label: "Prompt", value: "prompt" });
  //   }
  //   if(watch("attributes.conversation_flow_id")) {
  //     setPromptType({ label: "Conversation Flow", value: "conversation_flow" });
  //   }
  // }, [watch("systemPrompt"), watch("attributes.conversation_flow_id")])

  const handlePromptType = (selectedOption) => {
    setPromptType(selectedOption);
  };

  return (
    <Box px={4} py={2}>
      <Flex
        flexDirection={"column"}
        gap={2}
        height={watch("assistant_type") === "simple" ? "95%" : ""}
      >
        {/* <Box>
          <FormControl>
            <FormLabel>Select Prompt Type</FormLabel>
            <Select
              onChange={(selectedOption) => handlePromptType(selectedOption)}
              options={promptTypeOptions}
              value={promptType}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 999 }),
              }}
            />
          </FormControl>
        </Box> */}

        {/* {promptType?.value === "conversation_flow" && (
          <Box>
            <ConversationFlowSelect setValue={setValue} watch={watch} />
          </Box>
        )} */}

        <Box>
          <PromptTemplateSelect setValue={setValue} watch={watch} />
        </Box>
        <Box>
          {customFieldTags.map((keyword, index) => (
            <Tag key={index} colorScheme="blue" mr={2} mb={2}>
              {keyword}
            </Tag>
          ))}
        </Box>
        <Box>
          <FormControl height={"100%"} isInvalid={errors.systemPrompt}>
            <FormLabel>Prompt</FormLabel>
            <Textarea
              ref={inputRef}
              rows={watch("assistant_type") === "simple" ? 27 : 18}
              placeholder="System Prompt"
              {...register("systemPrompt", {
                required: "System Prompt is required",
              })}
              resize="vertical"
              onChange={(e) => {
                const text = e.currentTarget.value;
                setValue("systemPrompt", text);
                if (/\/$/.test(text)) contactOnOpen();
              }}
              h={"100%"}
            />
            <Text fontSize="sm" fontStyle="italic" mt={1}>
              {`To personalize, use your contact, dynamic, or your CRM variables within your prompt. Dynamic variables can just be enclosed within double curly braces. For rest, start by pressing "/"`}
            </Text>
            <FormErrorMessage>{errors.systemPrompt?.message}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <ContactFieldsList
        getValue={getValues("systemPrompt")}
        setValue={setValue}
        isOpen={contactIsOpen}
        onClose={contactOnClose}
        watch={watch}
      />
    </Box>
  );
};

export default Prompt;
