import { Box, Button, Input, Stack } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";

const Social = ({ brandingForm, getAgencies }) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = brandingForm;
  const agency = useContext(AgencyContext);
  const { textColor, agencyData, buttonColorScheme, colorPreset } = agency;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await fetchController(
        baseUrl + `/api/v1/agency/${agencyData.id}`,
        "PUT",
        data
      );
      getAgencies();
      toast.success("Social profiles updated successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wront...");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          spacing={4}
          mb="4"
          width={"50%"}
          justifyContent={"center"}
        >
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Twitter"} />
            <Input
              placeholder="Twitter"
              type="url"
              {...register("branding.socials.twitter")}
            />
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Facebook"} />
            <Input
              type="url"
              placeholder="Facebook"
              {...register("branding.socials.facebook")}
            />
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Linkedin"} />
            <Input
              type="url"
              placeholder="Linkedin"
              {...register("branding.socials.linkedin")}
            />
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Instagram"} />
            <Input
              type="url"
              placeholder="Instagram"
              {...register("branding.socials.instagram")}
            />
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Discord"} />
            <Input
              type="url"
              placeholder="Discord"
              {...register("branding.socials.discord")}
            />
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Docs"} />
            <Input
              type="url"
              placeholder="Docs"
              {...register("branding.socials.docs")}
            />
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Email"} />
            <Input
              type="email"
              placeholder="Email"
              {...register("branding.agency_mail")}
            />
          </Box>
        </Stack>

        <Button
          isLoading={isLoading}
          width="80px"
          colorScheme={buttonColorScheme}
          type="submit"
          mt="4"
          isDisabled={!agencyData}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default Social;
