import {
    Button,
    FormControl,
    FormLabel,
    Input,
    ModalBody,
    ModalFooter,
  } from "@chakra-ui/react";
  import React from "react";
  
  export default function BasicDataSourceForm({ nodeForm, onSubmit, onBack }) {
    const { register } = nodeForm.form;
    return (
      <>
        <form onSubmit={nodeForm.form.handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register("data.name")} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onBack}>
              Back
            </Button>
            <Button type="submit" colorScheme="yellow">
              Next
            </Button>
          </ModalFooter>
        </form>
      </>
    );
  }
  