import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import Select from "react-select";

export default function ResponseTypeSelectDSNode({
  nodeForm,
  onSubmit,
  onBack,
}) {
  const { register } = nodeForm.form;
  const { data = {} } = nodeForm.form.watch();
  const responseTypeOptions = [
    {
      value: "llm",
      label: "LLM",
      textFieldLabel: "Prompt",
    },
    {
      value: "static",
      label: "Static",
      textFieldLabel: "Text",
    },
  ];
  const selectedResponseOption = responseTypeOptions.find(
    (responseTypeOption) => responseTypeOption.value === data?.response?.type
  );
  return (
    <form onSubmit={nodeForm.form.handleSubmit(onSubmit)}>
      <ModalBody>
        <FormControl>
          <FormLabel>Response type</FormLabel>
          <Select
            options={responseTypeOptions}
            value={selectedResponseOption}
            onChange={({ value }) => {
              nodeForm.form.setValue("data.response.type", value);
            }}
          />
        </FormControl>
        {data?.response?.type ? (
          <FormControl>
            <FormLabel>{selectedResponseOption.textFieldLabel}</FormLabel>
            <Textarea {...register("data.response.value")} />
          </FormControl>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button type="submit" colorScheme="yellow">
          Next
        </Button>
      </ModalFooter>
    </form>
  );
}
