import { Flex, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import React from "react";

export default function ColorPicker({ label, form, name }) {
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"}>
      <FormControl
        style={{
          display: "flex",
          gap: "10px",
          width: "100%",
          flexDirection: "row",
        }}
      >
        <Input
          name="user_message_color"
          type="color"
          placeholder="User Message Color"
          borderRadius={15}
          p={1}
          className="widget__inputColor"
          border={0}
          height={"42px"}
          width={"42px"}
          {...form.register(name)}
        />

        <Text mt={3} colorScheme="teal" fontWeight={500} fontSize={"1rem"}>
          {label}
        </Text>
      </FormControl>
    </Flex>
  );
}
