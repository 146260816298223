import {
    Button,
    FormControl,
    FormLabel,
    Input,
    ModalBody,
    ModalFooter,
    Stack,
    Text,
  } from "@chakra-ui/react";
  import React from "react";
  import Select from "react-select";
  import useTools from "../../Tools/useTools"
  import useLinkToolUsers from "../../Tools/useLinkToolUsers";
  export default function ToolSelectDSForm({ onClose, nodeForm, onSubmit, onBack }) {
    const { setValue, watch } = nodeForm.form;
    const { data = {} } = watch();
    const { toolsList } = useTools();
    const toolOptions = toolsList.map((tool) => ({
      value: tool.id,
      label: tool.name,
    }));
    const selectedToolOption = toolOptions.find(
      (toolOption) => toolOption.value === data?.tool_id
    );
    const { linkToolUsers } = useLinkToolUsers({
      tool_id: data?.tool_id,
    });
    const toolConfigOptions = linkToolUsers.map((linkToolUserOption) => ({
      value: linkToolUserOption.id,
      label: linkToolUserOption.name,
    }));
    const selectedToolConfigMap = (data?.tool_users || []).reduce(
      (prev, current) => {
        prev[current] = true;
        return prev;
      },
      {}
    );
    return (
      <form onSubmit={nodeForm.form.handleSubmit(onSubmit)}>
        <ModalBody>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Tool</FormLabel>
              <Select
                options={toolOptions}
                onChange={({ value }) => {
                  setValue("data.tool_id", value);
                }}
                value={selectedToolOption}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Tool configs</FormLabel>
              <Select
                options={toolConfigOptions}
                isMulti
                value={toolConfigOptions.filter(
                  (toolConfigOption) =>
                    toolConfigOption.value in selectedToolConfigMap
                )}
                onChange={(selectedOptions) => {
                  setValue(
                    "data.tool_users",
                    selectedOptions.map((selectedOption) => selectedOption.value)
                  );
                }}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onBack}>
            Back
          </Button>
          <Button type="submit" colorScheme="yellow">
            Next
          </Button>
        </ModalFooter>
      </form>
    );
  }
  