import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { fileUploadFetch } from "../../utils/FetchController/fileUplaodFetch";
import { getHostDomain } from "../../utils/utils";

export default function useChatWidgetAvatars({ widgetId, cb }) {
  const baseUrl = getHostDomain();

  const onChangeConversationsBotIcon = async (file) => {
    try {
      const formData = new FormData();
      formData.append("upload_bot_icon", file);
      const url = baseUrl + `/api/v1/widget/${widgetId}/ConversationBotIcon`;
      await fileUploadFetch(formData, url);
      cb && cb();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const removeBotIcon = async () => {
    try {
      const url = baseUrl + `/api/v1/widget/${widgetId}/BubbleBotIcon`;
      await fetchController(url, "DELETE");
      cb && cb();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const removeConversationsBotIcon = async () => {
    try {
      const url = baseUrl + `/api/v1/widget/${widgetId}/ConversationBotIcon`;
      await fetchController(url, "DELETE");
      cb && cb();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const onChangeBotIcon = async (file) => {
    try {
      const formData = new FormData();
      formData.append("upload_bubble_bot_icon", file);
      const url = baseUrl + `/api/v1/widget/${widgetId}/BubbleBotIcon`;
      await fileUploadFetch(formData, url);
      cb && cb();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  return {
    onChangeBotIcon,
    onChangeConversationsBotIcon,
    removeBotIcon,
    removeConversationsBotIcon,
  };
}
