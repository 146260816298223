import { Alert, AlertIcon } from "@chakra-ui/react";

export default function AlertPayment({
  message = `Please add your payment method in order to activate any "add-on”`,
}) {
  return (
    <Alert status="info">
      <AlertIcon />
      {message}
    </Alert>
  );
}
