import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useConversationFlow from "./hooks/useConversationFlow";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function NewConversationFlowModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const { addFlow } = useConversationFlow();

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data) => {
    const payload = {
      name: data.name,
      num_nodes: 0,
      num_edges: 0,
      flow: {},
    };
    const response = await addFlow(payload);

    if(response?.status && response?.status !== 200) {
        toast.error(response?.message || "Something went wrong")
        handleClose();
    } else {
        toast.success(
          response?.message || "Conversation flow created successfully"
        );
        navigate(`/conversation-flow/${response?.data?.id}`);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create new conversation flow</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl isInvalid={errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter conversation flow name"
                {...register("name", { required: "Name is required" })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button isLoading={isSubmitting} type="submit" colorScheme="yellow">
              Create
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
