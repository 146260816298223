import React, { useEffect } from "react";

const useTitle = (agencyName) => {
  useEffect(() => {
    if (agencyName) {
      document.title = `${agencyName.charAt(0).toUpperCase()}${agencyName.slice(
        1
      )}: Build No-Code AI Voice & Chat Agents Fast`;
    }
  }, [agencyName]);
};
export default useTitle;
