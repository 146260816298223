import { AddIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { IoCallOutline, IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineCalendarToday, MdPictureAsPdf } from "react-icons/md";

export const actionButtonTypes = {
  calendar: {
    label: "Calendar",
    Icon: MdOutlineCalendarToday,
  },
  info: {
    label: "Information",
    Icon: IoInformationCircleOutline,
  },
  quote: {
    label: "Quotation",
    Icon: HiOutlineCurrencyDollar,
  },
  call: {
    Icon: IoCallOutline,
    label: "Call",
  },
  pdf: {
    Icon: MdPictureAsPdf,
    label: "PDF",
  },
};
export default function ActionButtonFormFields({
  handleActionButtonChange,
  actionButton,
  onChangeActionButton,
  onClickSubmit,
}) {
  return (
    <FormControl>
      <FormLabel>Action Buttons</FormLabel>
      <Flex gap={2} justifyContent={"center"} alignItems={"center"}>
        <ButtonGroup isAttached>
          {Object.entries(actionButtonTypes).map(
            ([actionButtonType, meta], index) => (
              <Tooltip key={index} label={meta.label}>
                <IconButton
                  isActive={actionButtonType === actionButton.icon}
                  icon={<meta.Icon />}
                  onClick={() =>
                    handleActionButtonChange({
                      name: "icon",
                      value: actionButtonType,
                    })
                  }
                />
              </Tooltip>
            )
          )}
        </ButtonGroup>
        <Input
          flex={1}
          onChange={onChangeActionButton}
          name="label"
          placeholder="Label"
          value={actionButton.label}
        />
        <Input
          flex={2}
          onChange={onChangeActionButton}
          name="link"
          placeholder="Link"
          value={actionButton.link}
        />
        <IconButton
          icon={<AddIcon />}
          colorScheme="blue"
          onClick={onClickSubmit}
        />
      </Flex>
    </FormControl>
  );
}
