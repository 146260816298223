import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useTools from "../../Tools/useTools";
import useLinkToolUsers from "../../Tools/useLinkToolUsers";
import Select from "react-select";
import useToolFunctions from "../../Tools/useToolFunctions";
import { AddIcon, CheckIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

export default function BasicToolFunctionForm({ onClose, nodeForm, onSubmit, onBack }) {
  const { setValue, watch, unregister } = nodeForm.form;
  const { data = {} } = watch();
  const { toolsList } = useTools();

  const [toolFunctionProperties, setToolFunctionProperties] = useState({})
  const [dataExtractionFields, setDataExtractionFields] = useState([])
  const [variableMapping, setVariableMapping] = useState({ "": "" });
  const [isDisableNewFieldBtn, setIsDisableNewFieldBtn] = useState(true);


  const toolOptions = toolsList.map((tool) => ({
    value: tool.id,
    label: tool.name,
  }));
  const selectedToolOption = toolOptions.find(
    (toolOption) => toolOption.value === data?.tool_id
  );

  const { linkToolUsers } = useLinkToolUsers({
    tool_id: data?.tool_id,
  });
  const toolConfigOptions = linkToolUsers.map((linkToolUserOption) => ({
    value: linkToolUserOption.id,
    label: linkToolUserOption.name,
  }));
  const selectedToolConfigMap = (data?.tool_users || []).reduce(
    (prev, current) => {
      prev[current] = true;
      return prev;
    },
    {}
  );

  const { toolFunctions } = useToolFunctions({
    tool_id: data?.tool_id,
  });
  const toolFunctionOptions = toolFunctions.map((toolFunctionOption) => ({
    value: toolFunctionOption.id,
    label: toolFunctionOption.name,
  }));
  const selectedToolFunctionOption = toolFunctionOptions.find(
    (toolFunctionOption) => toolFunctionOption.value === data?.tool_function
  );

  const handleToolFunctionVariableSelect = (oldItem, data) => {
    let obj = { ...variableMapping };
    delete obj[oldItem];
    obj[data.value] = { value: "", isDisabled: false };

    setVariableMapping(obj);
  }

  const handleDataExtractionSelect = (item, data) => {
    let newFields = { ...variableMapping };
    newFields[item].value = data.value;
    setVariableMapping(newFields);
    setValue(`data.variable_mappings.${item}`, data.value);
  };

  useEffect(() => {
    if(localStorage.getItem("dataExtractionFields")) {
      setDataExtractionFields(JSON.parse(localStorage.getItem("dataExtractionFields")))
    }
  }, [])

  const handleAddVariableMappingField = (item) => {
    let newFields = { ...variableMapping };

    delete newFields[""];
    newFields[item].isDisabled = true;

    setVariableMapping(newFields);
    setIsDisableNewFieldBtn(false);
  };

  const handleVariableMappingEdit = (item) => {
    let newFields = { ...variableMapping };
    newFields[item].isDisabled = false;
    setVariableMapping(newFields);
    setIsDisableNewFieldBtn(true);
  };

  const handleDeleteVariableMappingField = (item) => {
    let newFields = { ...variableMapping };
    delete newFields[item];
    unregister(`data.variable_mappings.${item}`);
    setVariableMapping(newFields);

    setIsDisableNewFieldBtn(!Object.keys(newFields).length === 0);
  };

  return (
    <>
      <form onSubmit={nodeForm.form.handleSubmit(onSubmit)}>
        <ModalBody>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Tool</FormLabel>
              <Select
                options={toolOptions}
                onChange={({ value }) => {
                  setValue("data.tool_id", value);
                }}
                value={selectedToolOption}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Tool configs</FormLabel>
              <Select
                options={toolConfigOptions}
                isMulti
                value={toolConfigOptions.filter(
                  (toolConfigOption) =>
                    toolConfigOption.value in selectedToolConfigMap
                )}
                onChange={(selectedOptions) => {
                  setValue(
                    "data.tool_users",
                    selectedOptions.map(
                      (selectedOption) => selectedOption.value
                    )
                  );
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Tool function</FormLabel>
              <Select
                options={toolFunctionOptions}
                value={selectedToolFunctionOption}
                onChange={({ value }) => {
                  setValue("data.tool_function", value);
                  const selectedToolFunction = toolFunctions.find(
                    (toolFunction) => toolFunction.id === value
                  );
                  setToolFunctionProperties(
                    selectedToolFunction?.details?.openai_spec?.function
                      ?.parameters?.properties
                  );
                }}
              />
            </FormControl>
            <RadioGroup
              onChange={(value) => setValue("data.tool_input", value)}
              value={data?.tool_input || ""}
            >
              <Stack direction="row">
                <Radio value="conversation">Conversation</Radio>
                <Radio value="variables">Variables</Radio>
              </Stack>
            </RadioGroup>
            <Box mt={3} display={"flex"}>
              <FormLabel>Tool input</FormLabel>
              <IconButton
                isRound={true}
                colorScheme="blue"
                variant="outline"
                aria-label="Add Field"
                icon={<AddIcon />}
                size={"xs"}
                title={"Add new field"}
                isDisabled={isDisableNewFieldBtn}
                onClick={() => {
                  setIsDisableNewFieldBtn(true);
                  setVariableMapping((prevState) => ({
                    ...prevState,
                    "": "",
                  }));
                }}
              />
            </Box>
            {data?.tool_input === "variables" &&
              Object.keys(variableMapping).length > 0 && (
                <>
                  {Object.keys(variableMapping).map((item, index) => (
                    <Flex key={item + index} gap={2}>
                      <Box width={"50%"}>
                        <Select
                          placeholder="Select tool function"
                          options={Object.keys(toolFunctionProperties).map(
                            (option) => ({
                              value: option,
                              label: option.replace("_", " "),
                              isDisabled: variableMapping[option]
                                ? true
                                : false,
                            })
                          )}
                          value={
                            variableMapping[item]
                              ? {
                                  value: item,
                                  label: Object.keys(toolFunctionProperties)
                                    .filter((key) => key === item)[0]
                                    ?.replace("_", " "),
                                }
                              : { value: "", label: "Select tool function" }
                          }
                          onChange={(selectedOption) =>
                            handleToolFunctionVariableSelect(
                              item,
                              selectedOption
                            )
                          }
                          isDisabled={variableMapping[item]?.isDisabled}
                        />
                      </Box>

                      <Box width={"50%"}>
                        <Select
                          placeholder="Select data extraction"
                          onChange={(selectedOption) =>
                            handleDataExtractionSelect(item, selectedOption)
                          }
                          options={dataExtractionFields?.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))}
                          value={
                            variableMapping[item]?.value
                              ? {
                                  value: variableMapping[item].value,
                                  label: dataExtractionFields?.filter(
                                    (opt) =>
                                      opt.id === variableMapping[item].value
                                  )[0]?.name,
                                }
                              : { value: "", label: "Select data extraction" }
                          }
                          isDisabled={
                            variableMapping[item] === "" ||
                            variableMapping[item]?.isDisabled
                          }
                          name={item}
                        />
                      </Box>

                      <IconButton
                          isRound={true}
                          colorScheme="blue"
                          variant="outline"
                          aria-label="Add"
                          icon={<CheckIcon />}
                          size={"xs"}
                          title="Add"
                          ml={2}
                          onClick={() => handleAddVariableMappingField(item)}
                          isDisabled={
                            !variableMapping[item]?.value ||
                            variableMapping[item].isDisabled
                          }
                        />
                        <IconButton
                          isRound={true}
                          colorScheme="blue"
                          variant="outline"
                          aria-label="Edit"
                          icon={<EditIcon />}
                          size={"xs"}
                          title="Edit"
                          ml={2}
                          isDisabled={!variableMapping[item]?.isDisabled}
                          onClick={() => handleVariableMappingEdit(item)}
                        />
                        <IconButton
                          isRound={true}
                          colorScheme="red"
                          variant="outline"
                          aria-label="Delete"
                          icon={<DeleteIcon />}
                          size={"xs"}
                          title="Delete"
                          ml={2}
                          onClick={() => handleDeleteVariableMappingField(item)}
                        />
                    </Flex>
                  ))}
                </>
              )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onBack}>
            Back
          </Button>
          <Button type="submit" colorScheme="yellow">
            Next
          </Button>
        </ModalFooter>
      </form>
    </>
  );
}
