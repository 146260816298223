import { FormControl, FormLabel } from "@chakra-ui/react";
import Select from "react-select";
import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function PromptTemplateSelect({ setValue, watch }) {
  const baseUrl = getHostDomain();

  const [status, setStatus] = useState("idle");
  const [promptTemplateOptions, setPromptTemplateOptions] = useState([]);
  const [promptIdMap, setPromptIdMap] = useState(new Map());

  const getPrompts = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        baseUrl + `/api/v1/prompt/list`,
        "GET"
      );
      const map = new Map();
      const options = response?.data?.items?.map((item) => {
        map.set(item.prompt_template, {
          label: item.name,
          value: item.prompt_template,
          id: item.id,
        });
        return {
          label: item.name,
          value: item.prompt_template,
          id: item.id,
        };
      });
      setPromptIdMap(map);
      setPromptTemplateOptions(options);
    } catch (error) {
      console.error(error);
    } finally {
      setStatus("idle");
    }
  };

  useEffect(() => {
    getPrompts();
  }, []);

  return (
    <FormControl>
      <FormLabel>Prompt Template</FormLabel>
      <Select
        onChange={({ value }) => {
          setValue("systemPrompt", value);
          setValue("templatePhonePrompt", value);
          setValue("attributes.conversation_flow_id", null);
        }}
        options={promptTemplateOptions}
        isLoading={status === "loading"}
        value={
          watch("systemPrompt") ? promptIdMap.get(watch("systemPrompt")) : ""
        }
      />
    </FormControl>
  );
}
