import { AddIcon, CheckIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";

export default function EdgePropertiesModal({
  isOpen,
  onClose,
  edgeForm,
  onConnect,
  nodes,
  edges,
  flowName,
}) {
  const { setValue, watch, handleSubmit, reset } = edgeForm;
  const { edgeProperties } = watch();

  const [isDisableNewFieldBtn, setIsDisableNewFieldBtn] = useState(true);
  const [variables, setVariables] = useState([
    {
      name: "",
      value: "",
      condition: "",
    },
  ]);

  const [variableDisableList, setVariableDisableList] = useState({ 0: false });

  const onSubmit = (data) => {
    onConnect(data.params, data.edgeProperties, nodes, edges, flowName);
    reset();
    onClose();
  };

  const handleAddVariableField = (item, index) => {
    let newFields = [...variables];
    setVariableDisableList((prevState) => ({ ...prevState, [index]: true }));

    setValue("edgeProperties.variables", newFields);
    setIsDisableNewFieldBtn(false);
  };

  const handleVariableEdit = (item, index) => {
    setVariableDisableList((prevState) => ({ ...prevState, [index]: false }));
  };

  const handleDeleteVariableField = (item, index) => {
    let newFields = [...variables];
    newFields.splice(index, 1);
    setValue("edgeProperties.variables", newFields);
    setVariables(newFields);
  };

  return (
    <Modal size={"5xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edge Properties</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={4}>
              <RadioGroup
                onChange={(value) => setValue("edgeProperties.type", value)}
                value={edgeProperties?.type || ""}
              >
                <Stack direction="row">
                  <Radio value="variables">Variables</Radio>
                  <Radio value="description">Description</Radio>
                </Stack>
              </RadioGroup>

              {edgeProperties?.type === "description" && (
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    onChange={(e) =>
                      setValue("edgeProperties.description", e.target.value)
                    }
                  />
                </FormControl>
              )}

              {edgeProperties?.type === "variables" && (
                <>
                  <Flex>
                    <FormLabel>Add Variables</FormLabel>
                    <IconButton
                      isRound={true}
                      colorScheme="blue"
                      variant="outline"
                      aria-label="Add Field"
                      icon={<AddIcon />}
                      size={"xs"}
                      title={"Add new field"}
                      isDisabled={isDisableNewFieldBtn}
                      onClick={() => {
                        setIsDisableNewFieldBtn(true);
                        setVariables([
                          ...variables,
                          { name: "", value: "", condition: "" },
                        ]);
                        setVariableDisableList({
                          ...variableDisableList,
                          [variables.length]: false,
                        });
                      }}
                    />
                  </Flex>
                  {variables.map((item, index) => (
                    <Flex key={index} gap={2} alignItems={"center"}>
                      <Box width={"30%"}>
                        <Input
                          value={item.name}
                          onChange={(e) => {
                            let data = [...variables];
                            data[index].name = e.target.value;
                            setVariables(data);
                          }}
                          placeholder="Enter name"
                          isDisabled={variableDisableList[index]}
                        />
                      </Box>
                      <Box width={"30%"}>
                        <Input
                          value={item.value}
                          onChange={(e) => {
                            let data = [...variables];
                            data[index].value = e.target.value;
                            setVariables(data);
                          }}
                          placeholder="Enter value"
                          isDisabled={variableDisableList[index]}
                        />
                      </Box>
                      <Box width={"30%"}>
                        <Select
                          placeholder="Select condition"
                          value={item.condition}
                          onChange={(e) => {
                            let data = [...variables];
                            data[index].condition = e.target.value;
                            setVariables(data);
                          }}
                          isDisabled={variableDisableList[index]}
                        >
                          <option value="==">{"=="}</option>
                          <option value=">=">{">="}</option>
                          <option value="<=">{"<="}</option>
                          <option value="!=">{"!="}</option>
                        </Select>
                      </Box>

                      <IconButton
                        isRound={true}
                        colorScheme="blue"
                        variant="outline"
                        aria-label="Add"
                        icon={<CheckIcon />}
                        size={"xs"}
                        title="Add"
                        ml={2}
                        isDisabled={variableDisableList[index]}
                        onClick={() => handleAddVariableField(item, index)}
                      />
                      <IconButton
                        isRound={true}
                        colorScheme="blue"
                        variant="outline"
                        aria-label="Edit"
                        icon={<EditIcon />}
                        size={"xs"}
                        title="Edit"
                        ml={2}
                        isDisabled={!variableDisableList[index]}
                        onClick={() => handleVariableEdit(item, index)}
                      />
                      <IconButton
                        isRound={true}
                        colorScheme="red"
                        variant="outline"
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        size={"xs"}
                        title="Delete"
                        ml={2}
                        onClick={() => handleDeleteVariableField(item, index)}
                        isDisabled={variables.length <= 1}
                      />
                    </Flex>
                  ))}
                </>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button type="submit" colorScheme="blue">
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
