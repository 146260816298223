import { CloseIcon } from "@chakra-ui/icons";
import { Tag, TagLabel, TagRightIcon, Wrap } from "@chakra-ui/react";
import React from "react";

export default function WidgetUserOpeningMessages({
  userOpeningMessages,
  onClick,
}) {
  return (
    <Wrap>
      {userOpeningMessages.map((message, index) => (
        <Tag variant={"subtle"} colorScheme="blue" size={"md"} key={index}>
          <TagLabel>{message}</TagLabel>
          <TagRightIcon
            boxSize="12px"
            as={CloseIcon}
            onClick={() => onClick(index)}
          />
        </Tag>
      ))}
    </Wrap>
  );
}
