import { useCallback, useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { toast } from "react-toastify";

export default function useWallet() {
  const [wallet, setWallet] = useState(null);
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    (async () => {
      setStatus("loading");
      const response = await fetchController("api/v1/billing/user_wallet/user");
      setWallet(response?.data);
      setStatus("idle");
    })();
  }, []);
  const onRechargeSubmit = async (values) => {
    try {
      setStatus("recharging");
      const response = await fetchController(
        "/api/v1/billing/user_wallet/buy_credits",
        "POST",
        { ...values, amount: parseFloat(values.amount) }
      );
      const toaster = response.status ? toast.success : toast.error;
      toaster(response.message);
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };
  const onUpdateStatus = useCallback((s) => {
    setStatus(s);
  }, []);
  return {
    wallet,
    status,
    onRechargeSubmit,
    onUpdateStatus,
  };
}
