import { Box, Button, Flex, Heading, useDisclosure } from "@chakra-ui/react";
import Layout from "../../Layout/Layout";
import { GoPlus } from "react-icons/go";
import { useContext, useEffect, useState } from "react";
import { AgencyContext } from "../../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../../utils/utils";
import { fetchController } from "../../../utils/FetchController/fetchController";
import Loader from "../../../utils/Loader/Loader";
import Table from "../../Tables/Table";
import PaginationSizeSelect from "../../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../../utils/Loader/Paginator";
import NewConversationFlowModal from "../NewConversationFlowModal";

export default function ConversationFlowTable() {
  const baseUrl = getHostDomain();

  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;

  const {
    isOpen: isNewConversationFlowOpen,
    onOpen: openNewConversationFlow,
    onClose: closeNewConversationFlow,
  } = useDisclosure();

  const [status, setStatus] = useState("idle");
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);

  const getConversationFlowList = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        `${baseUrl}/api/v1/conversation_flow/list?size=${paginationSize}&page=${page}`,
        "GET"
      );
      setPages(response?.data?.pages);

      if (response?.data) {
        const updatedData = response.data.items.map((item) => {
          return {
            name: item.name,
            ["nodes count"]: <Box ml={12}>{item.num_nodes}</Box>,
            ["edges count"]: <Box ml={12}>{item.num_edges}</Box>,
            id: item.id,
          };
        });
        const headsData = ["name", "nodes count", "edges count"];
        const columns = headsData.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));

        setTableData(updatedData);
        setHeads(columns);
      }
    } catch (error) {
      setStatus("error");
    } finally {
      setStatus("idle");
    }
  };

  useEffect(() => {
    getConversationFlowList();
  }, [page, paginationSize]);

  const handleAddFlow = () => {
    openNewConversationFlow()
  }

  return (
    <Layout>
      <Box
        border="1px"
        borderColor={"black"}
        borderRadius={"md"}
        h="90svh"
        p={5}
        overflowY={"auto"}
      >
        <Flex mb="4" justifyContent={"space-between"}>
          <Heading color={textColor} fontSize="xl">
            Conversation Flow
          </Heading>
          <Button
            leftIcon={<GoPlus />}
            colorScheme={buttonColorScheme}
            size={"sm"}
            onClick={handleAddFlow}
          >
            Add Flow
          </Button>
        </Flex>

        {status === "loading" ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={getConversationFlowList}
              columns={heads}
              data={tableData}
              table="conversationFlow"
            />

            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginBlock={2}
              gap={2}
            >
              <PaginationSizeSelect
                paginationSize={paginationSize}
                setPaginationSize={setPaginationSize}
                tableLength={tableData.length}
              />
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </Box>
        )}
      </Box>

      {isNewConversationFlowOpen && (
        <NewConversationFlowModal
          isOpen={isNewConversationFlowOpen}
          onClose={closeNewConversationFlow}
        />
      )}
    </Layout>
  );
}
