import { useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";

export default function useLlmModels() {
  const [llmModels, setLlModels] = useState([]);
  const [status, setStatus] = useState("loading");
  const baseUrl = getHostDomain();

  const fetchLlm = async () => {
    setStatus("loading");
    const responseLLms = await fetchController(
      baseUrl + "/api/v1/llmmodel/list"
    );
    setLlModels(responseLLms.data.items);
    setStatus("idle");
  };
  useEffect(() => {
    fetchLlm();
  }, []);
  const llmModelOptions = llmModels.map((llmModel) => ({
    value: llmModel.llm_model_label,
    label: llmModel.name,
  }));
  return {
    status,
    llmModelOptions,
    llmModels,
  };
}
