import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useLinkToolUsers({ tool_id }) {
  const baseUrl = getHostDomain();
  const [linkToolUsers, setLinkToolUsers] = useState([]);
  const [status, setStatus] = useState("loading");

  const getLinkToolUsers = async () => {
    try {
      if (!tool_id) return;
      setStatus("loading");
      const response = await fetchController(
        baseUrl + `/api/v1/tool/${tool_id}/LinkToolUser?size=100`
      );
      setLinkToolUsers(response.data.items);
    } catch (e) {
      console.error(e);
    } finally {
      setStatus("idle");
    }
  };
  useEffect(() => {
    getLinkToolUsers();
  }, [tool_id]);

  return { linkToolUsers, status, getLinkToolUsers };
}
