import {
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  Tooltip,
  Wrap,
} from "@chakra-ui/react";
import React from "react";
import { actionButtonTypes } from "./ActionButtonFormFields";
import { CloseIcon } from "@chakra-ui/icons";

export default function DisplayActionButtons({
  actionButtons,
  onClickCloseIcon,
}) {
  return (
    <Wrap>
      {actionButtons.map((actionButton, index) => (
        <Tooltip label={actionButton.link} key={index}>
          <Tag variant={"subtle"} colorScheme="blue" size={"md"} key={index}>
            <TagLeftIcon
              boxSize="12px"
              as={actionButtonTypes[actionButton.icon].Icon}
            />
            <TagLabel>{actionButton.label}</TagLabel>
            <TagRightIcon
              cursor={"pointer"}
              boxSize="12px"
              as={CloseIcon}
              onClick={() => onClickCloseIcon(index)}
            />
          </Tag>
        </Tooltip>
      ))}
    </Wrap>
  );
}
