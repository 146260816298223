import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { theme, useColorModeValue } from "@chakra-ui/react";
import { getHostDomain } from "../../../utils/utils";
import AuthContext from "../../Auth/AuthContext";
import axios from "axios";
const predefinedColors = [
  { color: theme.colors.yellow[400], name: "Yellow[400]" },
  { color: theme.colors.yellow[500], name: "Yellow[500]" },
  { color: theme.colors.yellow[600], name: "Yellow[600]" },
  { color: theme.colors.yellow[700], name: "Yellow[700]" },
  { color: theme.colors.yellow[800], name: "Yellow[800]" },
  { color: theme.colors.yellow[900], name: "Yellow[900]" },
  { color: theme.colors.blue[400], name: "Blue[400]" },
  { color: theme.colors.blue[500], name: "Blue[500]" },
  { color: theme.colors.blue[600], name: "Blue[600]" },
  { color: theme.colors.blue[700], name: "Blue[700]" },
  { color: theme.colors.blue[800], name: "Blue[800]" },
  { color: theme.colors.blue[900], name: "Blue[900]" },
  { color: theme.colors.green[400], name: "Green[400]" },
  { color: theme.colors.green[500], name: "Green[500]" },
  { color: theme.colors.green[600], name: "Green[600]" },
  { color: theme.colors.green[700], name: "Green[700]" },
  { color: theme.colors.green[800], name: "Green[800]" },
  { color: theme.colors.green[900], name: "Green[900]" },
  { color: theme.colors.red[400], name: "Red[400]" },
  { color: theme.colors.red[500], name: "Red[500]" },
  { color: theme.colors.red[600], name: "Red[600]" },
  { color: theme.colors.red[700], name: "Red[700]" },
  { color: theme.colors.red[800], name: "Red[800]" },
  { color: theme.colors.red[900], name: "Red[900]" },
  { color: theme.colors.gray[400], name: "Gray[400]" },
  { color: theme.colors.gray[500], name: "Gray[500]" },
  { color: theme.colors.gray[600], name: "Gray[600]" },
  { color: theme.colors.gray[700], name: "Gray[700]" },
  { color: theme.colors.gray[800], name: "Gray[800]" },
  { color: theme.colors.gray[900], name: "Gray[900]" },
  // { color: theme.colors.black, name: "Black" },
  { color: theme.colors.purple[400], name: "Purple[400]" },
  { color: theme.colors.purple[500], name: "Purple[500]" },
  { color: theme.colors.purple[600], name: "Purple[600]" },
  { color: theme.colors.purple[700], name: "Purple[700]" },
  { color: theme.colors.purple[800], name: "Purple[800]" },
  { color: theme.colors.purple[900], name: "Purple[900]" },
  { color: theme.colors.pink[400], name: "Pink[400]" },
  { color: theme.colors.pink[500], name: "Pink[500]" },
  { color: theme.colors.pink[600], name: "Pink[600]" },
  { color: theme.colors.pink[700], name: "Pink[700]" },
  { color: theme.colors.pink[800], name: "Pink[800]" },
  { color: theme.colors.pink[900], name: "Pink[900]" },
];
export const AgencyContext = createContext();
export const AgencyProvider = ({ children }) => {
  const baseUrl = getHostDomain();
  const [agencyData, setagencyData] = useState(null);
  const [myDetails, setMyDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [agencyName, setAgencyName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [themeColor, setThemeColor] = useState(null);
  const [colorPreset, setColorPreset] = useState(null);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [faviconUrl, setFaviconUrl] = useState(null);
  const [titleAgencyName, setTitleAgencyName] = useState(null);
  const cardBg = useColorModeValue("white", theme.colors.gray[700]);
  const { getUserData } = useContext(AuthContext);
  const checkIfResponseIsJson = (res) => {
    if (typeof res === "string") return null;
    return res;
  };
  const getMyDetails = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(
        window.location.origin + "/api/v1/agency/get_my_details"
      );
      const resStringAgency = checkIfResponseIsJson(response);
      if (!resStringAgency) throw new Error("Insighto");
      setMyDetails(response);
      const favLogoUrl = response?.branding?.logo_url?.includes(window.origin)
        ? response?.branding?.logo_url
        : baseUrl + response?.branding?.logo_url;
      setFaviconUrl(favLogoUrl);
      setTitleAgencyName(response?.branding?.agency_name);
      localStorage.setItem("appDomain", response?.domain?.appDomain);
      localStorage.setItem("API_SERVER_URL", response?.domain?.apiDomain);
      localStorage.setItem("chatbotDomain", response?.domain?.chatbotDomain);

      setColorPreset(response.branding?.color_preset || "#3182ce");
      const data = predefinedColors.filter(
        (color) => color.color === response.branding?.color_preset
      );
      const colorName =
        data?.length > 0
          ? data[0].name.split("[")[0]?.toLocaleLowerCase()
          : "blue";
      localStorage.setItem("btnColorScheme", colorName);
    } catch (error) {
      console.log(error);

      setFaviconUrl("https://cdn.insighto.ai/bot.png");
      setTitleAgencyName("Insighto.ai");
      localStorage.setItem("btnColorScheme", "blue");
      localStorage.setItem("appDomain", "app.insighto.ai");
      const base =
        process.env.REACT_APP_SERVER_URL ===
        "https://ragify-be-staging.azurewebsites.net"
          ? "ragify-be-staging.azurewebsites.net"
          : "api.insighto.ai";
      localStorage.setItem("API_SERVER_URL", base);
      localStorage.setItem("chatbotDomain", "cdn.insighto.ai");
      setColorPreset("#3182ce");
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const getAgencies = async () => {
    try {
      const response = await fetchController(
        baseUrl + `/api/v1/agency/list`,
        "GET"
      );
      setagencyData(response.data?.items[0]);
      setAgencyName(response.data?.items[0]?.branding?.agency_name);
      setColorPreset(
        response.data?.items[0]?.branding?.color_preset || "#3182ce"
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const handleCreateAgency = async () => {
    if (!agencyName) return;
    setIsLoading(true);
    let orgRes = null;
    try {
      if (!agencyData) {
        orgRes = await fetchController(
          baseUrl + `/api/v1/user/create_org`,
          "POST",
          {
            name: agencyName,
            description: "",
            domain: "",
          }
        );
      }
      const userDetails = await getUserData();
      const agencyEndpoint = !agencyData
        ? "/api/v1/agency"
        : `/api/v1/agency/${agencyData.id}`;
      const apiMethod = agencyData ? "PUT" : "POST";
      const payloadForPostReq = {
        org_id: agencyData?.org_id || orgRes?.data?.id,
        branding: {
          color_preset: themeColor,
          agency_name: agencyName,
          agency_mail: userDetails.data.email,
        },
        billing_plan: {},
        user_auth: {},
        domain: {},
      };
      const payloadForPutReq = {
        org_id: agencyData?.org_id || orgRes?.data?.id,
        branding: {
          ...(agencyData?.branding ? agencyData.branding : {}),
          color_preset: themeColor,
          agency_name: agencyName,
          agency_mail: userDetails.data.email,
        },
      };
      const agencyRes = await fetchController(
        agencyEndpoint,
        apiMethod,
        apiMethod === "POST" ? payloadForPostReq : payloadForPutReq
      );
      toast.success("Agency created successfully");
      getAgencies();
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  const handleBrandCreate = async () => {
    if (!agencyData) {
      toast.info("Please create agency first");
      return;
    }
    try {
      setIsPending(true);
      await fetchController(
        baseUrl + `/api/v1/agency/${agencyData.id}`,
        "PUT",
        {
          branding: {
            ...agencyData.branding,
            color_preset: themeColor,
          },
        }
      );
      toast.success("Brand created successfully");
      const selectedColor = predefinedColors.filter(
        (color, i) => color.color === themeColor
      );
      const colorName =
        selectedColor?.length > 0
          ? selectedColor[0].name.split("[")[0]?.toLocaleLowerCase()
          : null;
      localStorage.setItem("btnColorScheme", colorName);
      getAgencies();
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Something went wrong");
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    getMyDetails();
  }, []);

  useEffect(() => {
    setAgencyName(agencyData?.branding?.agency_name);
  }, [agencyData]);
  useEffect(() => {
    setThemeColor(colorPreset || "#3182ce");
  }, [colorPreset]);
  const buttonColorScheme = useColorModeValue(
    myDetails ? localStorage.getItem("btnColorScheme") || "blue" : "blue",
    "gray"
  );
  const textColor = useColorModeValue(
    myDetails ? colorPreset || "#3182ce" : "#3182ce",
    "#3182ce"
  );
  return (
    <AgencyContext.Provider
      value={{
        agencyData,
        myDetails,
        getAgencies,
        loading,
        error,
        handleCreateAgency,
        setAgencyName,
        setThemeColor,
        themeColor,
        colorPreset,
        textColor,
        agencyName,
        isLoading,
        handleBrandCreate,
        isPending,
        setIsPending,
        buttonColorScheme,
        predefinedColors,
        cardBg,
        faviconUrl,
        titleAgencyName,
        getMyDetails,
      }}
    >
      {children}
    </AgencyContext.Provider>
  );
};
