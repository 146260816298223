import { useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";

export const useHubSpot = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitForm = async (fields, hubspotutk, pageUri, pageName) => {
    const url =
      "https://api.hsforms.com/submissions/v3/integration/submit/46477053/ca807a2c-a87d-4f88-a80b-ee47acd33081";

    const payload = {
      fields: fields,
      context: {
        hutk: hubspotutk,
        pageUri: pageUri,
        pageName: pageName,
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow Insighto.ai Company to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Insighto.ai Company.",
            },
          ],
        },
      },
    };

    try {
      setIsLoading(true);
      setError(null);

      const response = await fetchController(url, "POST", payload, {
        "Content-Type": "application/json",
      });

      return response;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { submitForm, isLoading, error };
};

export default useHubSpot;
