import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useToolFunctions({ tool_id }) {
  const baseUrl = getHostDomain();
  const [toolFunctions, setToolFunctions] = useState([]);
  const [status, setStatus] = useState("loading");

  const getToolFunctions = async () => {
    try {
      if (!tool_id) return;
      setStatus("loading");
      const response = await fetchController(
        baseUrl +
          `/api/v1/tool/${tool_id}/tool_function/list?size=100`,
        "GET"
      );

      setToolFunctions(response?.data?.items);
    } catch (e) {
      console.error(e);
    } finally {
      setStatus("idle");
    }
  };

  useEffect(() => {
    getToolFunctions();
  }, [tool_id]);

  return { toolFunctions, status, getToolFunctions };
}
