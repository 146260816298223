import React from "react";
import { FileUploader } from "react-drag-drop-files";
import DragAndDropFileButton from "../DataSources/DataSourcesBegginers/DragDropFileButton";
import { Box } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";

export default function FilePicker({ handleChange, onClickDelete, label }) {
  return (
    <Box position={"relative"}>
      <MdDelete
        style={{
          position: "absolute",
          right: -10,
          top: -10,
          zIndex: 2,
        }}
        size={24}
        cursor={"pointer"}
        color="red"
        onClick={onClickDelete}
      />
      <FileUploader
        style={{
          flex: 1,
          width: "100%",
        }}
        name="file"
        handleChange={handleChange}
        multiple={false}
        types={["PNG", "SVG", "JPEG", "JPG"]}
      >
        <DragAndDropFileButton placeholder={label} />
      </FileUploader>
    </Box>
  );
}
