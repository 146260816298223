import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { LuDownload } from "react-icons/lu";

export default function DragAndDropFileButton({ placeholder }) {
  return (
    <Box
      borderRadius={"md"}
      padding={1}
      cursor={"pointer"}
      width={"100%"}
      border={"2px dotted #3182ce"}
    >
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <LuDownload size={30} />
        <Text fontSize={"xs"} color={"gray"} fontWeight={"bold"}>
          {placeholder || "Drag and drop files"}
        </Text>
        <Text fontSize={"xs"} fontWeight={"bold"}>
          {"or"}
        </Text>
        <Text fontSize={"xs"} color={"blue"}>
          {"Browse files"}
        </Text>
      </Flex>
    </Box>
  );
}
