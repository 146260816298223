import { addEdge, applyEdgeChanges, applyNodeChanges } from "@xyflow/react";
import { useCallback, useState } from "react";
import useConversationFlow from "./useConversationFlow";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function useAppFlowNodes(flowId) {
  const navigate = useNavigate();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [flowName, setFlowName] = useState(null);

  const { status, editFlow, getFlowById } = useConversationFlow();

  const updateFlow = async ({ flowId, nodes, edges, flowName, updateFn }) => {
    const payload = {
      name: flowName,
      num_nodes: nodes.length,
      num_edges: edges.length,
      flow: {
        nodes,
        edges,
      },
    };

    const response = await editFlow(flowId, payload);

    if (response?.status && response.status !== 200) {
      toast.error(response?.message || "Something went wrong");
    } else {
      updateFn();
    }
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );
  const onConnect = useCallback(
    async (params, data, nodes, edges, flowName) => {
      const edgesData = addEdge(
        { ...params, type: "smoothstep", animated: true, data },
        edges
      );

      await updateFlow({
        flowId,
        nodes,
        edges: edgesData,
        flowName,
        updateFn: () => setEdges(edgesData),
      });
    },
    []
  );

  const onAddNewNode = useCallback(async (values, nodes, edges, flowName) => {
    const node = makeNodeByData(values);
    const newNodes = [...nodes, node];

    await updateFlow({
      flowId,
      nodes: newNodes,
      edges,
      flowName,
      updateFn: () => setNodes(newNodes),
    });
  }, []);

  const onEditNode = useCallback(async (values, nodes, edges, flowName) => {
    const updatedNodeValue = {
      ...values,
      data: { ...values.data, label: values.data.name },
    };
    const updatedNodes = nodes.map((node) =>
      node.id === values.id ? updatedNodeValue : node
    );

    await updateFlow({
      flowId,
      nodes: updatedNodes,
      edges,
      flowName,
      updateFn: () => setNodes(updatedNodes),
    });
  }, []);

  const onDeleteEdge = useCallback(async (id, edges, nodes, flowName) => {
    const filteredEdges = edges.filter((edge) => edge.id !== id);

    await updateFlow({
      flowId,
      nodes,
      edges: filteredEdges,
      flowName,
      updateFn: () => setEdges(filteredEdges),
    });
  }, []);

  const operations = {
    onAddNewNode,
    onDeleteEdge,
    onEditNode,
  };
  const makeNodeByData = (values) => {
    const id = `${Date.now()}`;
    return {
      ...values,
      data: { ...values.data, label: values.data.name },
      id,
      position: { x: 0, y: 0 },
    };
  };

  const handleFlowAutoFill = async (id) => {
    const response = await getFlowById(id);

    if (response?.status && response.status !== 200) {
      toast.error(response?.message || "Something went wrong");
      navigate("/conversation-flow");
    } else {
      setFlowName(response.data.name);
      setNodes(response.data?.flow?.nodes || []);
      setEdges(response.data?.flow?.edges || []);
    }
  };

  return {
    onConnect,
    onEdgesChange,
    onNodesChange,
    handleFlowAutoFill,
    edges,
    nodes,
    flowName,
    operations,
    status,
  };
}
