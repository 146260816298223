import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { GoDotFill } from "react-icons/go";
import ChatMainScreenV2 from "./ChatMainScreenV2";
import InitMainScreen from "./InitMainScreen";
import { TbMessageChatbot } from "react-icons/tb";

export default function ChatWidgetV2({ widgetProperties }) {
  const {
    header_color,
    intro_message,
    user_opening_messages = [],
    display_name,
    header_text_color,
    remove_branding = false,
    bot_message_color = "#FFFFFFF",
    user_message_color = "#3b81f6;",
    bot_icon_color = "#3b81f6",
    user_text_message_color = "black",
    bot_text_message_color = "white",
    bubble_bot_icon,
    conversation_bot_icon,
    style_params,
  } = widgetProperties;
  const [isChatScreen, setIsChatScreen] = useState(false);
  const toggleIsChatScreen = useCallback(() => {
    setIsChatScreen(!isChatScreen);
  }, []);

  return (
    <Stack margin={"auto"} spacing={4}>
      <Flex gap={3} justifyContent={"center"} alignItems={"center"}>
        <Text>Welcome Screen</Text>
        <Switch
          isChecked={isChatScreen}
          onChange={(e) => setIsChatScreen(e.currentTarget.checked)}
        />
        <Text>Chat screen</Text>
      </Flex>
      <Stack position={"relative"} border={"1px solid lightgray"} spacing={3}>
        <Box bgColor={header_color} color={header_text_color} p={2}>
          <Heading fontSize={24}>{display_name}</Heading>
          <Flex
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={2}
          >
            <GoDotFill color="green" size={15} />
            <Text as={"span"}>Online</Text>
          </Flex>
        </Box>
        {isChatScreen ? (
          <ChatMainScreenV2
            header_color={header_color}
            user_message_color={user_message_color}
            user_text_message_color={user_text_message_color}
            bot_message_color={bot_message_color}
            bot_text_message_color={bot_text_message_color}
            remove_branding={remove_branding}
            conversation_bot_icon={conversation_bot_icon}
            user_opening_messages={user_opening_messages}
            style_params={style_params}
          />
        ) : (
          <InitMainScreen
            user_opening_messages={user_opening_messages}
            header_color={header_color}
            intro_message={intro_message}
            style_params={style_params}
            toggleIsChatScreen={toggleIsChatScreen}
            conversation_bot_icon={conversation_bot_icon}
          />
        )}
      </Stack>

      <Flex justifyContent={"flex-end"} alignItems={"center"}>
        <Box bg={bot_icon_color} p={2} borderRadius={"50%"}>
          {bubble_bot_icon ? (
            <Image src={bubble_bot_icon} width={10} borderRadius={"50%"} />
          ) : (
            <TbMessageChatbot color="white" size={20} />
          )}
        </Box>
      </Flex>
    </Stack>
  );
}
