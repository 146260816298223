import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import usePage from "./usePage";

export default function useLogs({
  logType = "tool_function_invoke_log",
  closeDeleteAlert,
}) {
  const [status, setStatus] = useState("loading");
  const [response, setResponse] = useState(null);
  const [paginationSize, setPaginationSize] = useState(10);
  const baseUrl = getHostDomain();
  const paginator = usePage();
  const logsDependency = [paginator.page, paginationSize, logType];
  const fetchLogs = useCallback(async () => {
    try {
      setStatus("loading");
      const url =
        baseUrl +
        `/api/v1/${logType}/list?page=${paginator.page}&size=${paginationSize}`;
      const response = await fetchController(url);
      setResponse(response);
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  }, logsDependency);

  useEffect(() => {
    fetchLogs();
  }, logsDependency);

  const deleteLog = useCallback(async (log) => {
    try {
      setStatus("deleting");
      const url = baseUrl + `/api/v1/tool_function_invoke_log/${log.id}`;
      await fetchController(url, "DELETE");
      toast.success("Tool function deleted");
      paginator.onSetPage(1);
      setStatus("success");
      closeDeleteAlert();
      fetchLogs();
    } catch (error) {
      setStatus("deleteError");
    }
  }, []);
  const onSetResponse = (res) => {
    setResponse(res);
  };
  return {
    status,
    onSetResponse,
    response,
    paginator,
    isLoading: status === "loading",
    hasError: status === "error",
    fetchLogs,
    deleteLog,
    paginationSize,
    setPaginationSize,
  };
}
