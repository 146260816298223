import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  ListItem,
  List,
  UnorderedList,
} from "@chakra-ui/react";

export default function FlowInstructionsModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How to create flow?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UnorderedList>
            <ListItem>Click on "+Step" to create a step.</ListItem>
            <ListItem>Give label to the step</ListItem>
            <ListItem>Give the type of process and press "Submit"</ListItem>
            <ListItem>To edit the step double click on step</ListItem>
            <ListItem>To delete the edge double click on edge</ListItem>
          </UnorderedList>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
