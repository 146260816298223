import { useDisclosure } from "@chakra-ui/react";
import { useCallback, useState } from "react";

export default function useSelectedLog({ selectedLogType }) {
  const [selectedLog, setSelectedLog] = useState(null);
  const {
    isOpen: isShowModalOpen,
    onClose: closeShowModal,
    onOpen: openShowModal,
  } = useDisclosure();
  const [selectedJson, setSelectedJson] = useState(null);
  const {
    isOpen: isCallDetailsModal,
    onClose: closeCallDetailsModal,
    onOpen: openCallDetailsModal,
  } = useDisclosure();
  const onOpenCallDetailsModal = useCallback((logJson) => {
    setSelectedJson(logJson);
    openCallDetailsModal();
  }, []);
  const onCloseCallDetailsModal = useCallback(() => {
    setSelectedJson(null);
    closeCallDetailsModal();
  }, []);
  const onOpenShowModal = useCallback(
    (log) => {
      setSelectedLog(log);
      openShowModal();
    },
    [selectedLog]
  );
  const onCloseShowModal = useCallback(() => {
    setSelectedLog(null);
    closeShowModal();
  }, []);
  const {
    isOpen: isDeleteAlertOpen,
    onClose: closeDeleteAlert,
    onOpen: openDeleteAlert,
  } = useDisclosure();

  const onDeleteLog = useCallback((log) => {
    setSelectedLog(log);
    openDeleteAlert();
  });

  return {
    selectedLog,
    isShowModalOpen,
    onCloseShowModal,
    onOpenShowModal,
    selectedJson,
    onOpenCallDetailsModal,
    onCloseCallDetailsModal,
    isCallDetailsModal,
    isDeleteAlertOpen,
    closeDeleteAlert,
    onDeleteLog,
  };
}
