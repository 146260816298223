import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import LogRowContactSync from "./LogRowContactSync";

export default function ContactSyncTable(props) {
  return (
    <TableContainer p={3} border={"1px solid lightgray"} borderRadius={"md"}>
      <Table size={"sm"} variant="striped">
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Tool Configuration</Th>
            <Th>Assistant name</Th>
            <Th>Extracted Data</Th>
            <Th>Timestamp</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.logs.map((log) => (
            <LogRowContactSync key={log.id} log={log} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
