import { Box, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";

export default function WalletBalance({ value = 0, currency = "USD" }) {
  return (
    <Box>
      <Text fontSize={"md"}>
        Your balance{" "}
        <Link
          color={"black"}
          textDecoration={"underline"}
          href="https://insightoai.freshdesk.com/support/solutions/articles/1070000091215-pay-as-you-go-wallet"
          target="_blank"
        >
          (How does it work?)
        </Link>
      </Text>
      <Heading fontSize={28}>
        <Text as={"span"}>{currency}</Text>{" "}
        <Text as={"span"} color={value > 0 ? "green" : "red"}>
          {value.toFixed(2)}
        </Text>
      </Heading>
    </Box>
  );
}
