import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import PasswordInput from "../Forms/PasswordInput";
import { MdLogin } from "react-icons/md";
import { toast } from "react-toastify";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { fetchController } from "../../utils/FetchController/fetchController";
import { SiMinutemailer } from "react-icons/si";
import { useContext, useState } from "react";
import { getCookieValue, getHostDomain, isValidDomain, validateRegex } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import useRecaptcha from "./useRecaptcha";
import useTitle from "../../utils/Hook/useTitle";
import { Link, useNavigate } from "react-router-dom";
import AgencyLogo from "./AgencyLogo";
import useHubSpot from "./useHubspot";

function RegisterForm() {
  const baseUrl = getHostDomain();
  const getRecaptchaToken = useRecaptcha();
  const navigate = useNavigate()
  const { submitForm } = useHubSpot();

  const emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$";

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [emailSent, setEmailSent] = useState(false);
  const [requestEmailVerify, setRequestEmailVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const requestEmailVerification = async (emailId) => {
    try {
      setRequestEmailVerify(true);
      await fetchController(
        `${baseUrl}/api/v1/login/verify_email_request`,
        "POST",
        {
          email: emailId,
        },
        {
          "Content-Type": "application/json",
        }
      );
      setEmailSent(true);
    } catch (e) {
      console.error(e);
      navigate("/verify-email")
    } finally {
      setRequestEmailVerify(false);
    }
  };

  const handleHubSpotRegister = (data) => {
    const fields = [
      {
        objectTypeId: "0-1",
        name: "email",
        value: data.email,
      },
      {
        objectTypeId: "0-1",
        name: "firstname",
        value: data.firstName,
      },
      {
        objectTypeId: "0-1",
        name: "lastname",
        value: data.lastName,
      },
    ];
    const hubspotutk = getCookieValue("hubspotutk");
    const pageUri = `${window.origin}/register`;
    const pageName = "register";

    submitForm(fields, hubspotutk, pageUri, pageName);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsValidate(true);
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.password ||
      formData.password !== formData.confirmPassword ||
      !validateRegex(emailPattern, formData.email)
    )
      return;

    try {
      setLoading(true);
      const reCaptchaToken = await getRecaptchaToken("signup");

      await fetchController(
        `${baseUrl}/api/v1/register`,
        "POST",
        {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          password: formData.password,
          is_active: false,
          captcha_response: reCaptchaToken,
        },
        {
          "Content-Type": "application/json",
        }
      );
      await requestEmailVerification(formData.email);
      if(isValidDomain(window.location.origin)) {
        handleHubSpotRegister(formData);
      }
    } catch (e) {
      toast.error(e?.response?.data?.detail);
    } finally {
      setLoading(false);
    }
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, agencyData, myDetails, titleAgencyName } = agency;
  const agency_id = myDetails ? myDetails?.agency_id : null;
  useTitle(titleAgencyName);

  return (
    <Box height={"100vh"} bgColor={"#F0F1F8"} p={1}>
      <Flex height={"100%"}>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          width={"50%"}
          spacing={4}
          py={10}
        >
          <Box>
            <AgencyLogo/>
          </Box>
          {!agency_id} &&{" "}
          <Heading size="xl" color={textColor}>
            {" "}
            Welcome
          </Heading>
          <Heading size="md" color={textColor}>
            {!agency_id
              ? " Register to Insighto.ai to continue to App."
              : "Register to Continue to App."}
          </Heading>
        </Stack>

        <Stack
          width={"50%"}
          px={10}
          py={20}
          spacing={6}
          justifyContent="center"
          alignItems={"center"}
          borderRadius={20}
          bgColor={"#C7C7C7"}
        >
          {!requestEmailVerify && !emailSent && (
            <>
              <Flex
                gap={4}
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                mb={10}
              >
                <MdLogin fontSize={"40px"} />
                <Heading size="xl">Register</Heading>
              </Flex>

              <form onSubmit={onSubmit} style={{ width: "80%" }}>
                <Flex direction={"column"} gap={6}>
                  <FormControl isInvalid={isValidate && !formData.firstName}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      name="firstName"
                      placeholder={"Enter your first name"}
                      autoComplete={"off"}
                      bgColor={"white"}
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    {isValidate && !formData.firstName && (
                      <FormErrorMessage>
                        {"First name is required"}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={isValidate && !formData.lastName}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      name="lastName"
                      placeholder={"Enter your last name"}
                      autoComplete={"off"}
                      bgColor={"white"}
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    {isValidate && !formData.lastName && (
                      <FormErrorMessage>
                        {"Last name is required"}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    isInvalid={
                      (isValidate && !formData.email) ||
                      (isValidate &&
                        !validateRegex(emailPattern, formData.email))
                    }
                  >
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      placeholder={"Enter your email"}
                      type="email"
                      bgColor={"white"}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {isValidate &&
                      (!formData.email ? (
                        <FormErrorMessage>
                          {"Email is required"}
                        </FormErrorMessage>
                      ) : (
                        !validateRegex(emailPattern, formData.email) && (
                          <FormErrorMessage>
                            {"Invalid email address"}
                          </FormErrorMessage>
                        )
                      ))}
                  </FormControl>

                  <FormControl isInvalid={isValidate && !formData.password}>
                    <FormLabel>Password</FormLabel>
                    <PasswordInput
                      placeholder={"Enter password"}
                      value={formData.password}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                    />
                    {isValidate && !formData.password && (
                      <FormErrorMessage>
                        {"Password is required"}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    isInvalid={
                      isValidate &&
                      formData.password !== formData.confirmPassword
                    }
                  >
                    <FormLabel>Confirm password</FormLabel>
                    <PasswordInput
                      placeholder={"Enter confirm password"}
                      value={formData.confirmPassword}
                      onChange={(e) =>
                        setFormData({ ...formData, confirmPassword: e.target.value })
                      }
                    />
                    {isValidate &&
                      formData.password !== formData.confirmPassword && (
                        <FormErrorMessage>
                          {"Password should match"}
                        </FormErrorMessage>
                      )}
                  </FormControl>

                  <Button
                    type="submit"
                    colorScheme={buttonColorScheme}
                    isLoading={loading}
                    w={"100%"}
                  >
                    {"Register"}
                  </Button>
                </Flex>
              </form>

              <Box fontWeight={"medium"} textAlign={"center"} mt={5}>
                Already have an Account?{" "}
                <Link
                  style={{ fontWeight: "bold", color: textColor }}
                  to="/login"
                >
                  {"Login"}
                </Link>
              </Box>
            </>
          )}

          {requestEmailVerify && !emailSent && (
            <Flex
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Spinner />
            </Flex>
          )}

          {!requestEmailVerify && emailSent && (
            <Flex
              gap={4}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mb={10}
            >
              <SiMinutemailer fontSize={"40px"} />
              <Heading size="xl">Check your email</Heading>
              <Text color={textColor} opacity={0.8}>
                We've sent a verification link to
              </Text>
              <Text color={textColor} fontWeight={"medium"} opacity={0.8}>
                {formData.email}
              </Text>

              <Box fontWeight={"medium"} textAlign={"center"} mt={5}>
                <Box as="span" opacity={0.5}>Didn't receive the email? </Box>
                <Box
                  as="span"
                  style={{ fontWeight: "bold", color: textColor, cursor: "pointer" }}
                  onClick={() => {
                    setEmailSent(false);
                    requestEmailVerification(formData.email)
                  }}
                >
                  {"Click to resend"}
                </Box>
              </Box>
            </Flex>
          )}
        </Stack>
      </Flex>
    </Box>
  );
}

export default function Register() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <RegisterForm />
    </GoogleReCaptchaProvider>
  );
}
