import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

export default function MessageChatV2({ messageNode }) {
  return (
    <Flex
      width={"100%"}
      justifyContent={messageNode.justifyContent}
      alignItems={"center"}
    >
      <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
        {messageNode.bot_icon && (
          <Image width={10} src={messageNode.bot_icon} />
        )}
        <Box
          color={messageNode.color}
          p={3}
          borderRadius={"md"}
          bg={messageNode.bg}
          maxW={500}
          textAlign={messageNode.textAlign}
        >
          <Text>{messageNode.message}</Text>
          <Text textAlign={"right"} fontSize={"xs"}>
            {messageNode.timestamp}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
