import { Stack } from "@chakra-ui/react";
import React from "react";
import CustomizationForm from "./CustomizationForm";
import ChatWidget from "./ChatWidget";

export default function WidgetCustomizationV1({
  onChangeWidgetCustomizationOption,
  setWidgetCustomizationOptions,
  buttons,
  setButtons,
  widgetCustomizationOptions,
  userOpeningMessages,
  setUserOpeningMessages,
  handleOnLoad,
  handleOnWidgetUpdate,
}) {
  return (
    <Stack direction="row" justifyContent={"space-around"} paddingInline={3}>
      <div
        style={{
          width: "100%",
          maxWidth: "550px",
        }}
      >
        <CustomizationForm
          onChangeWidgetCustomizationOption={onChangeWidgetCustomizationOption}
          setWidgetCustomizationOptions={setWidgetCustomizationOptions}
          buttons={buttons}
          setButtons={setButtons}
          widgetCustomizationOptions={widgetCustomizationOptions}
          userOpeningMessages={userOpeningMessages}
          setUserOpeningMessages={setUserOpeningMessages}
          handleOnLoad={handleOnLoad}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: "80svh",
            backgroundColor: "#D3D3D3",
            width: "0.5px",
          }}
        />
      </div>
      <div>
        <ChatWidget
          buttons={buttons}
          widgetCustomizationOptions={widgetCustomizationOptions}
          userOpeningMessages={userOpeningMessages}
          handleOnWidgetUpdate={handleOnWidgetUpdate}
        />
      </div>
    </Stack>
  );
}
