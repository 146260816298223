import { Button, Flex, Heading, useColorModeValue } from "@chakra-ui/react";
import "@xyflow/react/dist/style.css";
import { MdAdd } from "react-icons/md";
export default function OperationalHeader(props) {
  const bg = useColorModeValue("gray.50", "gray.700");
  return (
    <Flex
      p={3}
      bg={bg}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={3}
    >
      <Heading as="h4" size="md">
        {props.flowName}
      </Heading>
      <Button
        onClick={props.onOpen}
        colorScheme="blue"
        size={"sm"}
        leftIcon={<MdAdd />}
      >
        Step
      </Button>
    </Flex>
  );
}
