import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { Background, Controls, ReactFlow } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import Layout from "../Layout/Layout";
import FlowInstructionsModal from "./FlowInstructionModal";
import NodeModal from "./NodeModal";
import OperationalHeader from "./OperationalHeader";
import useAppFlowNodes from "./hooks/useAppFlowNodes";
import useNodeForm from "./hooks/useNodeForm";
import EdgePropertiesModal from "./EdgePropertiesModal";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../utils/Loader/Loader";

export default function ConversationFlow() {
  const { id } = useParams();

  const {
    onConnect,
    onEdgesChange,
    onNodesChange,
    handleFlowAutoFill,
    edges,
    nodes,
    flowName,
    operations,
    status,
  } = useAppFlowNodes(id);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isInstrctonModalOpen,
    onOpen: openInstructionModal,
    onClose: closeInstructionModal,
  } = useDisclosure();
  const {
    isOpen: isEdgePropertiesOpen,
    onOpen: openEdgeProperties,
    onClose: closeEdgeProperties,
  } = useDisclosure();

  const nodeForm = useNodeForm({
    operations,
    onCloseForm: onClose,
  });
  const edgeForm = useForm({
    defaultValues: { edgeProperties: { type: "description" } },
  });

  useEffect(() => {
    if (id) handleFlowAutoFill(id);
  }, [id]);

  return (
    <Layout>
      <Box w={"100%"} overflowY={"auto"} h={"90svh"}>
        {status === "loading" && <Loader />}

        <Box height={"100%"}>
          <OperationalHeader
            onOpen={() => {
              onOpen();
              nodeForm.form.reset({})
            }}
            flowName={flowName}
          />

          <Box height={"85%"}>
            <ReactFlow
              nodes={nodes}
              onNodeDoubleClick={(_, node) => {
                nodeForm.form.reset({ ...node });
                onOpen();
              }}
              onNodesChange={onNodesChange}
              onEdgeDoubleClick={(_, edge) => {
                operations.onDeleteEdge(edge.id, edges, nodes, flowName);
              }}
              edges={edges}
              onConnect={(params) => {
                edgeForm.setValue("params", params);
                openEdgeProperties();
              }}
              onEdgesChange={onEdgesChange}
              fitView
            >
              <Background />
              <Controls />
            </ReactFlow>
          </Box>

          <Box p={2}>
            <Button colorScheme="yellow" onClick={openInstructionModal}>
              Instructions
            </Button>
          </Box>
        </Box>
      </Box>

      <NodeModal
        isOpen={isOpen}
        onClose={onClose}
        operations={operations}
        nodeForm={nodeForm}
        nodes={nodes}
        edges={edges}
        flowName={flowName}
      />
      <FlowInstructionsModal
        isOpen={isInstrctonModalOpen}
        onClose={closeInstructionModal}
      />
      {isEdgePropertiesOpen && (
        <EdgePropertiesModal
          isOpen={isEdgePropertiesOpen}
          onClose={closeEdgeProperties}
          edgeForm={edgeForm}
          onConnect={onConnect}
          nodes={nodes}
          edges={edges}
          flowName={flowName}
        />
      )}
    </Layout>
  );
}
